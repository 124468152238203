.main-section {
    background: var(--white--alt);
    top: 0;
    left: 0;
}

.img-box.right {
    position: absolute;
    width: 46vw;
    max-width: 46vw;
    z-index: 0;
    overflow: hidden;
}

.img-box.right .library {
    object-fit: cover;
}

.img-box.right .shape {
    left: 0;
    width: auto !important;
    height: 100% !important;
    object-fit: contain;
    position: absolute;
    top: 0;
    height: 100%;
    width: auto;
    z-index: 1;
}

.main-section section .container {
    z-index: 1;
}

.main-section section .container .row {
    z-index: 2;
}

.email {
    color: var(--primary);
}

.email:hover {
    color: rgba(10, 48, 135, 0.8)
}

@media only screen and (min-width: 992px) {
    h1 {
        font-size: 3.2rem;
    }

    p {
        font-size: 1.05rem;
    }

    .text-container {
        z-index: 1;
    }

    .img-container {
        position: absolute;
        z-index: 0;
        right: 0;
        top: 100vh;
        width: 48vw;
        max-width: 48vw;
    }
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 3rem;
    }

    p {
        font-size: 1rem;
    }

    .main-section {
        height: auto;
    }
}
