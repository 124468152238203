.pdf-container {
    border-radius: 10px;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary);
}

.pdf-document {
    display: flex;
    flex-direction: row;
}

.pdf-page {
    border-right: 1px solid var(--secondary);
}

.pdf-page span {
    display: none;
}

.pdf-page canvas {
    width: 100% !important;
    height: auto !important;
}
.pdf-actions {
    position: absolute;
    align-self: center;
    bottom: 4rem;
    border: none;
    background: var(--white--alt);
    padding: 10px;
    border-radius: 5px;
}

.pdf-actions button {
    border: none;
    background: transparent;
}

.pdf-actions button:hover {
    cursor: pointer;
}
