.main {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary);
}

.DeJureFadedHeader {
    width: 100%;
    height: auto;
    z-index: 0;
    position: absolute;
}

.DeJureScaleHeader {
    width: 60%;
    height: auto;
    z-index: 1;
    position: absolute;
}

.DeJureScaleHeader img {
    width: 100%;
    height: auto;
    opacity: 0.3
}

.LogoHeader {
    width: 45%;
    height: auto;
    z-index: 3;
    position: absolute;
}

.LogoHeader img {
    width: 100%;
    height: auto;
}

.ArrowDown {
    bottom: 5%;
    position: absolute;
}

.ArrowDown svg path {
    stroke: var(--white--alt);
    transition: 0.2s ease;
}

.ArrowDown:hover {
    cursor: pointer;
}

.ArrowDown:hover svg path{
    stroke: var(--secondary)
}

@media only screen and (max-width: 800px) {
    .DeJureScaleHeader {
        width: 85% !important;
    }

    .LogoHeader {
        width: 65% !important;
    }
}
