.journal-card {
    border: none;
}

.journal-card-img {
    box-shadow: var(--soft);
    -webkit-box-shadow: var(--soft);
    border-radius: 10px;
    max-width: 300px;
    max-height: 400px;
    width: 100%;
    height: auto;
}

.add-journal-card {
    min-width: 100%;
    height: 90%;
    border: 2px dashed var(--primary);
    border-radius: 10px;
    box-shadow: var(--soft);
    -webkit-box-shadow: var(--soft);
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-journal-card path {
    stroke: var(--primary) !important;
}

.journal-card-text {
    margin-top: 1rem;
}

a:hover, a:focus {
    text-decoration: none;
    outline: none;
}

hr {
    border: 1px solid var(--secondary);
}

.back-link {
    display: flex;
    align-items: center;
}

.back-link-text {
    font-size: 1.3rem;
    color: var(--primary);
}

.journal-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.journal-card-overlay:hover {
    cursor: pointer;
}

.overlay-action {
    height: auto;
    width: 100%;
    position: absolute;
    left: 0;
    background: var(--secondary);
    color: var(--primary);
    font-weight: bold;
    font-size: 1.2rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.delete-action {
    top: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.permissions-action {
    top: 62%;
}

.view-action {
    bottom: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    color: var(--secondary);
    background: var(--primary);
}




.action-icon {
    margin-left: auto;
}

.blur {
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
    -webkit-filter: url(#blur-filter);
    filter: url(#blur-filter);
    -webkit-filter: blur(3px);
    filter: blur(3px);
}

.blur-svg {
    display: none;
}
