@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
:root {
    /* Color styles */
    --primary: rgba(10, 48, 135, 1);
    --secondary: rgba(181, 229, 200, 1);
    --white--alt: rgba(251, 245, 243, 1);
    --dark: rgba(38, 28, 21, 1);

    --light: #CBCEDD;

    /* Effect styles */
    --base:  22px 4px 4px rgba(0, 0, 0, 0.25);
    --soft:  40px 10px 10px rgba(38, 28, 21, 0.1), 40px -10px 10px rgba(38, 28, 21, 0.1), 40px 10px -10px rgba(38, 28, 21, 0.1), 40px -10px -10px rgba(38, 28, 21, 0.1);
    --soft--primary:  10px 0px 4px rgba(10, 48, 135, 0.15);
}

h1, h2, h3, h4, h5, h6, p, a, button, span {
    font-family: 'Roboto', sans-serif;
    color: var(--dark);
}

.bold {
    font-weight: bold !important;
}

.bg-primary {
    background: var(--primary) !important;
}

.bg-secondary {
    background: var(--secondary) !important;
}

.bg-white {
    background: var(--white--alt) !important;
}

.text-primary {
    color: var(--primary) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

.shadow-soft-primary {
    box-shadow: var(--soft--primary);
    -webkit-box-shadow: var(--soft--primary);
}

.full-screen {
    height: 100vh;
    width: 100%;
    background: var(--white--alt);
    position: relative;
}

.overflow-hidden {
    overflow: hidden;
}

.img-cover {
    width: 100vw;
    height: auto;
    object-fit: contain;
    margin-left: calc(50% - 50vw);
    /* position: relative;
    top: 0;
    left: 0px;
    width: 120%;
    margin-left: -10%;
    height: 100%;
    max-height: 60%;
    object-fit: cover;
    object-position: center center; */
}

form input, form textarea, form select {
    border: 2px solid var(--primary) !important;
    border-radius: 0 !important;
    width: 100% !important;
    outline: none !important;
    padding-left: 0.5rem !important;
}

form input, form select {
    height: 50px !important;
}

form label {
    color: var(--primary) !important;
}

form button {
    margin-bottom: 2rem;
}

.input-image-container {
    height: 300px;
    overflow: hidden;
}

.input-image-container img {
    height: 300px;
    width: 100%;
    object-fit: cover;
}

button {
    background: var(--primary);
    padding: 0.75rem 2rem 0.75rem 2rem;
    font-weight: bold;
    font-size: 13;
    color: var(--white--alt);
    border: none;
    outline: none;
    text-align: center;
}

button:hover {
    cursor: pointer;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    background: -webkit-linear-gradient(45deg, #B5E5C8 10%, #0A3081 10%, #0A3081 90%, #B5E5C8 90%);
    background: -o-linear-gradient(45deg, #B5E5C8 10%, #0A3081 10%, #0A3081 90%, #B5E5C8 90%);
    background: linear-gradient(45deg, #B5E5C8 10%, #0A3081 10%, #0A3081 90%, #B5E5C8 90%);
}

form svg path {
    stroke: var(--primary);
}
form a {
    color: var(--primary);
}

.display-flex {
    display: flex;
}

.loader {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-left: 2rem;
    margin-right: 2rem;
    animation: spin 1s linear infinite;
    -webkite-animation: spin 1s linear infinite;
}

.loader-white {
    border-top: 2px solid var(--white--alt);
}

.loader-primary {
    border-top: 2px solid var(--primary);
}

.dropzone {

    margin-bottom: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7rem;
    border-width: 2px;
    border-radius: 2px;
    border-color: var(--primary);
    border-style: dashed;
    background-color: var(--secondary);
    color: var(--primary);
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
}

.dropzone:focus {
    border-color: var(--primary);
}

.dropzone.disabled {
    opacity: 0.6;
}

.modal .close-icon {
    margin-top: 1rem;
    margin-left: auto;
    margin-right: -1rem;
    margin-top: -2rem;
}

.modal .close-icon:hover {
    cursor: pointer;
}

.editor-toolbar .easymde-dropdown, .editor-toolbar button {
    color: var(--primary) !important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (min-width: 1200px) {
    .overflow-xl-hidden {
        overflow: hidden;
    }
}
