.admin-articles .container .row .col-lg-6 button {
    float: right !important;
}

@media only screen and (max-width: 768px) {
    .admin-articles .container .row .col-lg-6 button {
        float: left !important;
        margin-bottom: 2rem;
    }
}
