.login-register {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-register svg path {
    fill: var(--primary) !important;
}

.login-register form button {
    min-width: 250px;
}

.login-register .password-trigger {
    position: absolute;
    top: 25%;
    right: 1.5rem;
    color: var(--primary);
    z-index: 9999;
    user-select: none;
}
