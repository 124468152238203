.access-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.access-input-group .access-input-button {
    padding: 1rem;
    border: 1px solid var(--primary);
}

.access-input-group .access-input-button path {
    stroke: var(--light);
}

.access-input-group .access-input-button.active {
    cursor: pointer;
    background: var(--secondary);
}

.access-input-group .access-input-button:hover {
    cursor: pointer;
    background: var(--secondary);
}

.access-input-group .access-input-button.active path {
    stroke: var(--primary);
}

.access-input-group .access-input-button:hover path {
    stroke: var(--primary);
}
