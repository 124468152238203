nav {
    min-height: 80px;
    position: sticky;
    z-index: 9999;
}

.navbar {
    border-radius: 0;
    min-height: 80px;
    align-items: center;
}

.navbar-nav li {
    font-weight: bold;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.navbar-brand img {
    max-width: 80px !important;
}

.nav-item, .nav-link {
    text-decoration: none;
    outline: none;
    color: var(--white--alt) !important;
    background: var(--primary) !important;
}

.nav-item:hover, .nav-link:hover {
    color: var(--secondary) !important;
    background: var(--primary) !important;
}

footer {
    position: relative;
    left: 0;
    width: 100%;
}

footer svg path {
    fill: var(--secondary) !important;
}

.active .nav-link {
    color: var(--secondary) !important;
}

@media only screen and (max-width: 1220px) {
    .navbar li {
        margin: 0.5rem;
    }

    footer .img-fluid {
        max-width: 200px;
    }
}
