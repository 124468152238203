.col-lg-8.article-card img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.col-lg-6.article-card img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.col-lg-4.article-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

@media only screen and (max-width: 749px) {
    .article-card img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 300px;
        margin-bottom: 1rem;
    }
}
